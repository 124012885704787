import { RootState } from 'store/store';

export const selectGetLicenseGeneration = (state: RootState) => state.license.licensesGeneration.licenses;
export const selectLicenseGenerationPage = (state: RootState) => state.license.licensesGeneration.page;
export const selectLicenseGenerationTotalItems = (state: RootState) => state.license.licensesGeneration.totalItems;
export const selecttLicenseGenerationItemsPerPage = (state: RootState) => state.license.licensesGeneration.itemsPerPage;
export const selectGetLicenseGenerationStatus = (state: RootState) => state.license.licensesGeneration.getLicensesGenerationStatus;
export const selectGetLicenseGenerationError = (state: RootState) => state.license.licensesGeneration.error;

export const selectGeneratedLicenseFile = (state: RootState) => state.license.licensesGeneration.generatedLicenseFile;
export const selectGeneratedLicenseFileName = (state: RootState) => state.license.licensesGeneration.generatedLicenseFileName;
export const selectGeneratedLicenseFileStatus= (state: RootState) => state.license.licensesGeneration.generateLicenseFileStatus;
export const selectGeneratedLicenseFileError = (state: RootState) => state.license.licensesGeneration.error;

export const selectGetLicenseById = (state: RootState) => state.license.licenseById.license;
export const selectGetLicenseByIdStatus= (state: RootState) => state.license.licenseById.getLicenseByIdStatus;
export const selectGetLicenseByIdError = (state: RootState) => state.license.licenseById.error;

export const selectGetLicensesHistory = (state: RootState) => state.license.licensesHistory.licenses;
export const selectLicenseHistoryPage = (state: RootState) => state.license.licensesHistory.page;
export const selectLicenseHistoryTotalItems = (state: RootState) => state.license.licensesHistory.totalItems;
export const selectLicenseHistoryItemsPerPage = (state: RootState) => state.license.licensesHistory.itemsPerPage;
export const selectGetLicenseHistoryStatus= (state: RootState) => state.license.licensesHistory.getLicensesHistoryStatus;
export const selectGetLicenseHistoryError = (state: RootState) => state.license.licensesHistory.error;