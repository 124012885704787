import { useCallback, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { selectUpdateAccountPasswordStatus, selectAccountActionsError, selectAccountActionsMessage } from 'store/user/userSelector';
import { updateAccountPassword } from 'store/user/userThunk';
import { resetUpdateAccountPasssword } from 'store/user/userSlice';
import { UpdateAccountPasswordForm } from 'core/user/User';
import PageContainer from 'components/layouts/PageContainer';

const UpdateAccountPasswordScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accountActionsMessage = useSelector(selectAccountActionsMessage);
  const accountActionsError = useSelector(selectAccountActionsError);
  const updateAccountPasswordStatus = useSelector(selectUpdateAccountPasswordStatus);
  const isUpdateLoading = updateAccountPasswordStatus === RequestStatus.LOADING;
  const isUpdateSuccess = updateAccountPasswordStatus === RequestStatus.SUCCEESS;

  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  }
  const buttonItemLayout = {
    wrapperCol: {
      span: 14,
      offset: 8,
    },
  }
  const buttonErrorLabelLayout = {
    wrapperCol: {
      span: 14,
      offset: 8,
    }
  }

  const onFinish = async (values: UpdateAccountPasswordForm) => {
    dispatch(updateAccountPassword({ oldPassword: values.oldPassword, newPassword: values.newPassword }))
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({
        key: 'password',
        message: `Success`,
        description: 'Password successfully updated',
        placement: 'bottomRight',
      });
      if (accountActionsMessage) {
        notification.warning({
          key: 'passwordEmail',
          message: `Email notification was failed`,
          description: accountActionsMessage,
          placement: 'bottomRight',
        });
      }
      history.push(routes.account.path);
    }
  }, [history, isUpdateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  
  useEffect(() => {
    return () => {
      dispatch(resetUpdateAccountPasssword());
    }
  }, [dispatch]);

  const onClose = useCallback(() => {
    if (history.length < 3) {
      history.push("/account");
    } else {
      history.goBack();
    }
  }, [history]);

  return (
    <PageContainer title="Account password update">
      <Container>
        <Form
          {...formItemLayout}
          layout="horizontal"
          onFinish={onFinish}
        >
          <Form.Item
            rules={[{ required: true }]}
            name="oldPassword"
            label="Old password"
          >
            <Input.Password
              autoComplete="off"
              placeholder="Please enter old password"
              visibilityToggle={true}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New password"
            rules={[
              { required: true },
              {
                // eslint-disable-next-line
                pattern: new RegExp("^[A-Za-z0-9\"\'!#$%&()*+,-./:;<=>?@^_`{|}~]*$"),
                message: "Password should contain only latin letters, numbers and special characters!"
              },
              {
                pattern: new RegExp("(?=.*[A-Z])"),
                message: "At least one uppercase letter required!"
              },
              {
                // eslint-disable-next-line
                pattern: new RegExp("(?=.*[\"\'!#$%&()*+,-./:;<=>?@^_`{|}~])"),
                message: "At least one special character required!"
              },
              {
                pattern: new RegExp(".*[0-9].*"),
                message: "At least one digit required!"
              },
              {
                min: 8,
                message: "At least 8 symbols required!"
              },
            ]}
          >
            <Input.Password
              placeholder="Please enter new password"
              visibilityToggle={true}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Please confirm new password" autoComplete="off" />
          </Form.Item>
          <Form.Item {...buttonErrorLabelLayout} style={{ marginBottom: '0' }}>
            <ErrorLabel>{accountActionsError}</ErrorLabel>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button loading={isUpdateLoading} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Update password</Button>
            <Button type="default" onClick={onClose}>Cancel</Button>
          </Form.Item>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default UpdateAccountPasswordScreen;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;
