import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { License } from 'core/license/License';
import ItemsPerPage from 'core/shared/enum/ItemsPerPage';
import RequestStatus from 'core/shared/enum/RequestStatus';

import { LicensesGenerationState, LicenseByIdState, LicensesHistoryState } from './licenseTypes';

const licensesGenerationInitialState: LicensesGenerationState = {
  getLicensesGenerationStatus: RequestStatus.IDLE,
  licenses: [],
  page: 1,
  totalItems: 0,
  itemsPerPage: ItemsPerPage.TEN,
  generateLicenseFileStatus: RequestStatus.IDLE,
  generatedLicenseFile: null,
  generatedLicenseFileName: '',
  error: '',
};

export const licensesGenerationSlice = createSlice({
  name: 'licensesSlice',
  initialState: licensesGenerationInitialState,
  reducers: {
    getLicensesGenerationRequest: (state) => {
      state.error = '';
      state.getLicensesGenerationStatus = RequestStatus.LOADING;
    },
    getLicensesGenerationSucceess: (state, action: PayloadAction<{licenses: License[], totalItems: number }>) => {
      const { payload } = action;
      state.getLicensesGenerationStatus = RequestStatus.SUCCEESS;
      state.licenses = payload.licenses;
      state.totalItems = payload.totalItems;
    },
    getLicensesGenerationFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getLicensesGenerationStatus = RequestStatus.FAILURE;
    },
    updateLicensesGenerationPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    updateLicensesGenerationItemsPerPage: (state, action: PayloadAction<ItemsPerPage>) => {
      const { payload } = action;
      state.itemsPerPage = payload;
    },
    resetGetLicensesGeneration: (state) => {
      state.getLicensesGenerationStatus = RequestStatus.IDLE;
      state.licenses = [];
      state.error = '';
    },
    generateLicenseFileRequest: (state) => {
      state.generateLicenseFileStatus = RequestStatus.LOADING;
      state.error = '';
    },
    generateLicenseFileSucceess: (state, action: PayloadAction<{ file: File, name: string }>) => {
      const { payload } = action;
      state.generatedLicenseFile = payload.file;
      state.generatedLicenseFileName = payload.name;
      state.generateLicenseFileStatus = RequestStatus.SUCCEESS;
    },
    generateLicenseFileFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.generateLicenseFileStatus = RequestStatus.FAILURE;
    },
    resetGenerateLicenseFile: (state) => {
      state.generateLicenseFileStatus = RequestStatus.IDLE;
      state.generatedLicenseFile = null;
      state.generatedLicenseFileName = '';
      state.error = '';
    },
  },
});

const licensesHistoryInitialState: LicensesHistoryState = {
  getLicensesHistoryStatus: RequestStatus.IDLE,
  licenses: [],
  page: 1,
  totalItems: 0,
  itemsPerPage: ItemsPerPage.TEN,
  error: '',
};

export const licensesHistorySlice = createSlice({
  name: 'licensesSlice',
  initialState: licensesHistoryInitialState,
  reducers: {
    getLicensesHistoryRequest: (state) => {
      state.error = '';
      state.getLicensesHistoryStatus = RequestStatus.LOADING;
    },
    getLicensesHistorySucceess: (state, action: PayloadAction<{licenses: License[], totalItems: number }>) => {
      const { payload } = action;
      state.getLicensesHistoryStatus = RequestStatus.SUCCEESS;
      state.licenses = payload.licenses;
      state.totalItems = payload.totalItems;
    },
    getLicensesHistoryFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getLicensesHistoryStatus = RequestStatus.FAILURE;
    },
    updateLicensesHistoryPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    updateLicensesHistoryItemsPerPage: (state, action: PayloadAction<ItemsPerPage>) => {
      const { payload } = action;
      state.itemsPerPage = payload;
    },
    resetGetLicensesHistory: (state) => {
      state.getLicensesHistoryStatus = RequestStatus.IDLE;
      state.licenses = [];
      state.error = '';
    },
  },
});

const licenseByIdInitialState: LicenseByIdState = {
  getLicenseByIdStatus: RequestStatus.IDLE,
  license: null,
  error: '',
};

export const licenseByIdSlice = createSlice({
  name: 'licenseByIdSlice',
  initialState: licenseByIdInitialState,
  reducers: {
    getLicenseByIdRequest: (state) => {
      state.error = '';
      state.getLicenseByIdStatus = RequestStatus.LOADING;
    },
    getLicenseByIdSucceess: (state, action: PayloadAction<License>) => {
      const { payload } = action;
      state.license = payload;
      state.getLicenseByIdStatus = RequestStatus.SUCCEESS;
    },
    getLicenseByIdFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getLicenseByIdStatus = RequestStatus.FAILURE;
    },
    resetGetLicenseById: () => ({ ...licenseByIdInitialState }),
  },
});

export const {
  getLicensesGenerationRequest,
  getLicensesGenerationSucceess,
  getLicensesGenerationFailure,
  updateLicensesGenerationPage,
  updateLicensesGenerationItemsPerPage,
  resetGetLicensesGeneration,
  generateLicenseFileRequest,
  generateLicenseFileSucceess,
  generateLicenseFileFailure,
  resetGenerateLicenseFile
} = licensesGenerationSlice.actions;

export const {
  getLicensesHistoryRequest,
  getLicensesHistorySucceess,
  getLicensesHistoryFailure,
  updateLicensesHistoryPage,
  updateLicensesHistoryItemsPerPage,
  resetGetLicensesHistory,
} = licensesHistorySlice.actions;

export const {
  getLicenseByIdRequest,
  getLicenseByIdSucceess,
  getLicenseByIdFailure,
  resetGetLicenseById
} = licenseByIdSlice.actions;

export default combineReducers({
  licensesGeneration: licensesGenerationSlice.reducer,
  licensesHistory: licensesHistorySlice.reducer,
  licenseById: licenseByIdSlice.reducer,
});
