import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import { Button, Form, Upload, notification } from 'antd';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import {
  selectGeneratedLicenseFile,
  selectGeneratedLicenseFileStatus,
  selectGeneratedLicenseFileError,
  selectGetLicenseById,
  selectGeneratedLicenseFileName
} from 'store/license/licenseSelector';
import { UploadOutlined } from '@ant-design/icons';
import { generateLicenseFile, getLicenseById } from 'store/license/licenseThunk';
import { resetGetLicenseById, resetGenerateLicenseFile } from 'store/license/licenseSlice';
import Title from 'antd/lib/typography/Title';
import { LicenceGenerateForm } from 'core/license/License';
import PageContainer from 'components/layouts/PageContainer';

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
}
const buttonItemLayout = {
  wrapperCol: {
    span: 14,
    offset: 8,
  },
}
const buttonErrorLabelLayout = {
  wrapperCol: {
    span: 14,
    offset: 8,
  }
}

const LicenseGenerate: React.FC = () => {
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  
  const license = useSelector(selectGetLicenseById);
  const licenseFile = useSelector(selectGeneratedLicenseFile);
  const generatedLicenseFileStatus = useSelector(selectGeneratedLicenseFileStatus);
  const generatedLicenseFileName = useSelector(selectGeneratedLicenseFileName);
  const generatedLicenseFileError = useSelector(selectGeneratedLicenseFileError);

  const isGenerationLoading = generatedLicenseFileStatus === RequestStatus.LOADING;
  const isGenerationSuccess = generatedLicenseFileStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: LicenceGenerateForm) => {
    dispatch(generateLicenseFile(id, values.file.file));
  };
  
  useEffect(() => {
    if (isGenerationSuccess) {
      notification.success({
        message: `Success`,
        description: 'License generated successfully.',
        placement: 'bottomRight',
      });
    }
  }, [dispatch, isGenerationSuccess, id]);

  useEffect(() => {
    dispatch(getLicenseById(id));
    return () => {
      dispatch(resetGetLicenseById());
      dispatch(resetGenerateLicenseFile());
    }
  }, [dispatch, id]);

  const props = {
    beforeUpload: () => {
      return false;
    },
    maxCount: 1,
  };

  return (
    <PageContainer title="License Generation - Upload Request File">
      <Container>
        <Title level={3}>Generate license <b>{license?.name}</b></Title>
        <Form
          {...formItemLayout}
          layout="horizontal"
          onFinish={onFinish}
          style={{ marginTop: '12px' }}
        >
          <Form.Item rules={[{ required: true }]} name="file" valuePropName="file" label="Upload: ">
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          {generatedLicenseFileError && <Form.Item {...buttonErrorLabelLayout}>
            <ErrorLabel>{generatedLicenseFileError}</ErrorLabel>
          </Form.Item>}
          <Form.Item {...buttonItemLayout}>
            <Button loading={isGenerationLoading} disabled={isGenerationSuccess} style={{ marginRight: '10px' }} htmlType="submit" type="primary">Generate</Button>
            <Link to={routes.licenseGenerationList.path}>
              <Button type="default">Cancel</Button>
            </Link>
            {licenseFile &&
              (<a href={window.URL.createObjectURL(new Blob([licenseFile], { type: 'application/zip' }))} download={generatedLicenseFileName}>
                <Button
                  type="primary"
                  style={{ backgroundColor: '#52C41A', borderColor: 'inherit', color: 'white', marginTop: '10px' }}
                >
                  Download license
                </Button>
              </a>)
            }
          </Form.Item>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default LicenseGenerate;

const ErrorLabel = styled.div`
  color: #ff4d4f;
`;

const Container = styled.div`
  max-width: 500px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;
