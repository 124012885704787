import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';

import SignInScreen from 'pages/signin/SignInScreen';
import NotFoundScreen from 'pages/not-found/NotFoundScreen';
import Dashboard from 'pages/dashboard/Dashboard';

import { selectIsAuth } from 'store/user/userSelector';
import AuthRoute from 'components/routing/AuthRoute';
import { useAppConfig } from 'components/context/AppConfigContext';
import RouteType from 'core/shared/enum/RouteType';
import { GlobalStyles } from 'styles/GlobalStyles';
import RequestResetPassword from 'pages/reset-password/RequestResetPassword';
import ResetPassword from 'pages/reset-password/ResetPassword';

interface LocationState {
  from?: string
};

const App: React.FC = () => {
  const { routes } = useAppConfig();
  const { pathname, state } = useLocation();

  const from = (state as LocationState)?.from || "";

  const isAuth = useSelector(selectIsAuth);

  return (
    <>
      <GlobalStyles />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Redirect 
          from={routes.index.path}
          to={isAuth ? (from ? from : routes.licenseGenerationList.path) : routes.signin.path}
          exact
        />
        <AuthRoute
          type={RouteType.GUEST}
          isAuth={isAuth}
          path={routes.signin.path}
          exact={routes.signin.exact}
          component={SignInScreen}
        />
        <AuthRoute
          type={RouteType.GUEST}
          isAuth={isAuth}
          path={routes.requestRecoverPassword.path}
          exact={routes.requestRecoverPassword.exact}
          component={RequestResetPassword}
        />
        <AuthRoute
          type={RouteType.GUEST}
          isAuth={isAuth}
          path={routes.recoverPassword.path}
          exact={routes.recoverPassword.exact}
          component={ResetPassword}
        />
        <AuthRoute
          type={RouteType.PRIVATE}
          isAuth={isAuth}
          path={[
            routes.licenseGenerationList.path,
            routes.licenseGenerate.path,
            routes.licenseHistory.path,
            routes.licensesProfile.path,
            routes.account.path,
            routes.accountPasswordUpdate.path,
            routes.instructionsForUse.path,
          ]}
          exact={routes.index.exact}
          component={Dashboard}
        />
        <Route path="/404">
          <NotFoundScreen />
        </Route>
        <Route path="*">
          <NotFoundScreen />
        </Route>
      </Switch>
    </>
  );
};

export default App;
