import ApiMethods from 'core/shared/enum/ApiMethod';

import { Account, UpdateAccountPasswordForm } from 'core/user/User';
import { SignInRequest } from 'core/user/UserRequest';
import { SignInResponse } from 'core/user/UserResponse';

import http from './request';

const signIn = (data: SignInRequest): Promise<SignInResponse> => {
  return http.request({
    url: '/auth/signin/client',
    method: ApiMethods.POST,
    data,
  });
};

const getAccountData = (): Promise<{ data: Account }> => {
  return http.request({
    url: '/clients/profile',
    method: ApiMethods.GET,
  });
};

const updateAccountPassword = (data: UpdateAccountPasswordForm): Promise<{ data: { data: { token?: string }, message: string | null }}> => {
  return http.request({
    url: '/clients/profile',
    method: ApiMethods.PUT,
    data,
  });
};

const signOut = (): Promise<{}> => {
  return http.request({
    url: '/auth/signout/client',
    method: ApiMethods.GET,
  });
};

const requestResetPassword = (email: string): Promise<{}> => {
  return http.request({
    url: '/clients/reset-password',
    method: ApiMethods.POST,
    data: {
      email
    }
  });
};

const validateResetPasswordToken = (token: string): Promise<{}> => {
  return http.request({
    url: '/clients/reset-password/validate',
    method: ApiMethods.POST,
    data: {
      token
    }
  });
};

const resetPassword = (password: string, token: string): Promise<{}> => {
  return http.request({
    url: '/clients/reset-password/update',
    method: ApiMethods.POST,
    data: {
      password,
      token
    }
  });
};

const getAppData = (): Promise<{ data: { appVersion: string } }> => {
  return http.request({
    url: '/management/version',
    method: ApiMethods.GET,
  });
};

const logError = (error: string, stacktrace: string): Promise<{}> => {
  return http.request({
    url: '/logs',
    method: ApiMethods.POST,
    data: {
      shortDescription: error,
      stacktrace,
    },
  });
};

const UserService = {
  signIn,
  getAccountData,
  updateAccountPassword,
  signOut,
  getAppData,
  logError,
  requestResetPassword,
  validateResetPasswordToken,
  resetPassword,
};

export default UserService;
