import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import dateFormat from "dateformat";
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography } from 'antd';

import { selectGetLicenseById } from 'store/license/licenseSelector';
import { downloadLicense, getLicenseById } from 'store/license/licenseThunk';
import { resetGetLicenseById } from 'store/license/licenseSlice';
import PageContainer from 'components/layouts/PageContainer';

const { Title } = Typography;

const LicenseProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const license = useSelector(selectGetLicenseById);

  useEffect(() => {
    dispatch(getLicenseById(id));
    return () => {
      dispatch(resetGetLicenseById());
    }
  }, [dispatch, id]);

  return (
    <PageContainer title="License">
      {license &&
        <>
          <Profile>
            <Title level={3}>License <b>{license.name}</b></Title>
            <Line>
              <Label>Name:</Label>
              <Info>{license.name || '<No info>'}</Info>
            </Line>
            <Line>
              <Label>Configuration:</Label>
              <Info>
                <ProductType>{license.baseConfiguration.productName}{' '}</ProductType>
                    (<span>
                  {!license.baseConfiguration.count && !license.baseConfiguration.days && <span>Permanent</span>}
                  {license.baseConfiguration.count && <span>Count of uses: {license.baseConfiguration.count};</span>}
                  {license.baseConfiguration.count && license.baseConfiguration.days && ' '}
                  {license.baseConfiguration.days && <span>Days: {license.baseConfiguration.days};</span>}
                </span>)
                    <span>
                  {
                    license.configurations.map((config, index) => (
                      <div key={index}>
                        <ProductType>+ {config.productName}{' '}</ProductType>
                          (<span>
                          {!config.count && !config.days && <span>Permanent</span>}
                          {config.count && <span>Count of uses: {config.count};</span>}
                          {config.count && config.days && ' '}
                          {config.days && <span>Days: {config.days};</span>}
                        </span>)
                      </div>
                    ))
                  }
                </span>
              </Info>
            </Line>
            <Line>
              <Label>Generated by:</Label>
              <Info>
                {
                  !license.generatedBy ? '<No info>' : (
                    <span>
                      {license.generatedBy.firstName} {license.generatedBy.lastName}{' '}({license.generatedBy.email})
                    </span>
                  )
                }
              </Info>
            </Line>
            <Line>
              <Label>Generation date:</Label>
              <Info>
                {
                  !license.generatedBy ? '<No info>' : (
                    <span>
                      {dateFormat(new Date(license.generationDate), "dd.mm.yyyy HH:MM:ss")}
                    </span>
                  )
                }
              </Info>
            </Line>
          </Profile>
          <ButtonContainer>
            {
              license.generationDate ? (
                <Button type="primary" size="middle" style={{ margin: '10px 10px' }} onClick={() => dispatch(downloadLicense(license.id))}>Download</Button>
              ) : (
                  <Link style={{ margin: '10px 10px' }} to={`/license-generation/${license?.id}/generate`} replace>
                    <Button type="primary" size="middle">Generate</Button>
                  </Link>
                )
            }
            <Button type="default" size="middle" style={{ margin: '10px 10px' }} onClick={() => license.generationDate ? history.push("/license-history") : history.push("/license-generation/licenses")}>Close</Button>
          </ButtonContainer>
        </>
      }
    </PageContainer>
  );
};

export default LicenseProfileScreen;

const Profile = styled.div`
  max-width: 400px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  width: 400px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
`;

const Line = styled.div`
  display: flex;
`;

const Label = styled.div`
  width: 150px;
  font-weight: bold;
  line-height: 22px;
  border-bottom: 1px solid #F0F0F0;
  margin-right: 10px;
  padding: 10px 0 10px;
  text-align: right;
`;

const Info = styled.div`
  width: 240px;
  text-align: left;
  line-height: 22px;
  border-bottom: 1px solid black;
  padding: 10px 0 10px;
  border-bottom: 1px solid #F0F0F0;
`;

const ProductType = styled.span`
  font-weight: 500;
  font-style: italic;
`;