import { useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { FileAddOutlined, ProfileOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { Switch, NavLink, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import RouteType from 'core/shared/enum/RouteType';

import LicenseGenerationList from 'pages/license-generation/LicenseGenerationList';
import LicenseGenerate from 'pages/license-generation/license-generate/LicenseGenerate';
import LicenseHistory from 'pages/licenses-history/LicenseHistory';
import LicenseProfile from 'pages/license-profile/LicenseProfile';
import AccountProfile from 'pages/account-profile/AccountProfile';
import UpdateAccountPasswordScreen from 'pages/account-profile/UpdateAccountPasswordScreen';

import { selectIsAuth, selectUserData } from 'store/user/userSelector';
import { signOut } from 'store/user/userThunk';

import AuthRoute from 'components/routing/AuthRoute';
import { useAppConfig } from 'components/context/AppConfigContext';
import { resetPagination } from 'store/license/licenseThunk';

import InstructionsForUse from 'pages/instructions-for-use/InstructionsForUse';

import { ReactComponent as IfuLogo } from 'assets/ifu.svg';

const { Header, Sider, Content } = Layout;

const Dashboard: React.FC = () => {
  const { routes } = useAppConfig();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isAuth = useSelector(selectIsAuth);
  const userData = useSelector(selectUserData);
  const onLogout = () => dispatch(signOut());

  useEffect(() => {
    return () => {
      dispatch(resetPagination());
    }
  }, [dispatch]);

  return (
    <Layout style={{ minHeight: '100%', height: 'fit-content' }}>
      <StyledHeader>
        <Link to={routes.index.path}>
          <Logo>SamanTree Medical &#8203;- License Management System</Logo>
        </Link>
        <SideElement>
          <span>Username: {userData.username} </span>
          <Logout style={{ padding: "10px 10px 10px 10px" }} onClick={onLogout}>
              <LogoutOutlined />
          </Logout>
        </SideElement>
      </StyledHeader>
      <Layout>
        <Sider
          theme="light"
          width="210px"
          collapsible
          breakpoint="lg"
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[routes.licenseGenerationList.path]}
            selectedKeys={[pathname]}
            onClick={() => dispatch(resetPagination())}
          >
            <Menu.Item key={routes.licenseGenerationList.path}  icon={(<FileAddOutlined />)}>
              <NavLink to={routes.licenseGenerationList.path}>
                <LinkSpan>License Generation</LinkSpan>
              </NavLink>
            </Menu.Item>
            <Menu.Item key={routes.licenseHistory.path}  icon={(<ProfileOutlined />)}>
              <NavLink to={routes.licenseHistory.path}>
                <LinkSpan>License History</LinkSpan>
              </NavLink>
            </Menu.Item>
            <Menu.Item key={routes.account.path}  icon={(<UserOutlined />)}>
              <NavLink to={routes.account.path}>
                <LinkSpan>Account Profile</LinkSpan>
              </NavLink>
            </Menu.Item>
            <Menu.Item key={routes.instructionsForUse.path}  icon={<IfuLogo />}>
              <NavLink to={routes.instructionsForUse.path}>
                <LinkSpan>IFU</LinkSpan>
              </NavLink>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content>
          <Switch>
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.licenseGenerationList.path}
              exact={routes.licenseGenerationList.exact}
              component={LicenseGenerationList}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.licenseGenerate.path}
              exact={routes.licenseGenerate.exact}
              component={LicenseGenerate}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.licenseHistory.path}
              exact={routes.licenseHistory.exact}
              component={LicenseHistory}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.licensesProfile.path}
              exact={routes.licensesProfile.exact}
              component={LicenseProfile}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.account.path}
              exact={routes.account.exact}
              component={AccountProfile}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.accountPasswordUpdate.path}
              exact={routes.accountPasswordUpdate.exact}
              component={UpdateAccountPasswordScreen}
            />
            <AuthRoute
              type={RouteType.PRIVATE}
              isAuth={isAuth}
              path={routes.instructionsForUse.path}
              exact={routes.instructionsForUse.exact}
              component={InstructionsForUse}
            />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;

const StyledHeader = styled(Header)`
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1100px) {
    font-size: 16px;
  }
  
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

const Logo = styled.div`
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  padding: 15px 0;
  margin-right: 20px;
`;

const Logout = styled.div`
  margin-left: 30px;
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;
`;

const LinkSpan = styled.span` 
  font-size: 14px;
  line-height: 22px;
`;

const SideElement = styled.div`
  display: flex;
  align-items: center;
`;