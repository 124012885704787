import PageContainer from 'components/layouts/PageContainer';

const InstructionsForUse: React.FC = () => {

  return (
    <PageContainer title="Instructions for use">
        Instructions will be published later.
    </PageContainer>
  );
};

export default InstructionsForUse;
