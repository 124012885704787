import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { PageHeader, Layout, Table, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { License } from 'core/license/License';
import RequestStatus from 'core/shared/enum/RequestStatus';
import { getLicensesGeneration } from 'store/license/licenseThunk';
import { resetGetLicensesGeneration, updateLicensesGenerationItemsPerPage, updateLicensesGenerationPage } from 'store/license/licenseSlice';
import { selectGetLicenseGeneration, selectGetLicenseGenerationStatus, selectLicenseGenerationPage, selectLicenseGenerationTotalItems, selecttLicenseGenerationItemsPerPage } from 'store/license/licenseSelector';
const { Content } = Layout;

const columns: ColumnsType<License> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text, record) => (
      <Link to={`/licenses/${record.id}`} replace>{record.name}</Link>
    ),
  },
  {
    title: 'Configuration',
    dataIndex: 'configuration',
      render: (text, record) => (
        <div>
          <ProductType>{record.baseConfiguration.productName}{' '}</ProductType>
          (<span>
            {!record.baseConfiguration.count && !record.baseConfiguration.days && <span>Permanent</span>}
            {record.baseConfiguration.count && <span>Count of uses: {record.baseConfiguration.count};</span>}
            {record.baseConfiguration.count && record.baseConfiguration.days && ' '}
            {record.baseConfiguration.days && <span>Days: {record.baseConfiguration.days};</span>}
          </span>)
          <span>
            {
              record.configurations.map((config, index) => (
                <div key={index}>
                  <ProductType>+ {config.productName}{' '}</ProductType>
                  (<span>
                    {!config.count && !config.days && <span>Permanent</span>}
                    {config.count && <span>Count of uses: {config.count};</span>}
                    {config.count && config.days && ' '}
                    {config.days && <span>Days: {config.days};</span>}
                  </span>)
                </div>
              ))
            }
          </span>
        </div>
      ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <>
        <Space size="middle" style={{ wordBreak: "normal"}}>
          <Link to={`/license-generation/${record.id}/generate`}>
            Generate
          </Link>
        </Space>
      </>
    ),
  },
];

const LicenseGeneration: React.FC = () => {
  const dispatch = useDispatch();

  const getLicenseGenerationStatus = useSelector(selectGetLicenseGenerationStatus);
  const licensesGeneration = useSelector(selectGetLicenseGeneration);
  const licenseGenerationPage = useSelector(selectLicenseGenerationPage);
  const licenseGenerationTotalItems = useSelector(selectLicenseGenerationTotalItems);
  const licenseGenerationItemsPerPage = useSelector(selecttLicenseGenerationItemsPerPage);

  useEffect(() => {
    dispatch(getLicensesGeneration(licenseGenerationPage, licenseGenerationItemsPerPage));
  }, [dispatch, licenseGenerationPage, licenseGenerationItemsPerPage]);

  useEffect(() => {
    return () => {
      dispatch(resetGetLicensesGeneration());
    }
  }, [dispatch]);

  const onPageChange = (page: number) => dispatch(updateLicensesGenerationPage(page));

  return (
    <>
      <PageHeader
        title="License Generation - Select License to Generate"
        ghost={false}
      >
        Based on your agreement with SamanTree Medical you can generate the following licenses:
      </PageHeader>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: '#fff',
        }}
      >
        <Table
          title={() => (<TitleContainer>
            <h2>Licenses</h2>
          </TitleContainer>)}
          loading={getLicenseGenerationStatus === RequestStatus.LOADING}
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: licenseGenerationItemsPerPage,
            current: licenseGenerationPage,
            total: licenseGenerationTotalItems,
            onChange: onPageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {dispatch(updateLicensesGenerationPage(1)); dispatch(updateLicensesGenerationItemsPerPage(size))},
            showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={columns}
          dataSource={licensesGeneration}
        />
      </Content>
    </>
  );
};

export default LicenseGeneration;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductType = styled.span`
  font-weight: 500;
  font-style: italic;
`;
