import { updateLicensesGenerationPage, updateLicensesHistoryPage, updateLicensesGenerationItemsPerPage, updateLicensesHistoryItemsPerPage } from 'store/license/licenseSlice';
import { AppThunk, AppDispatch } from 'store/store';
import {
  getLicensesGenerationRequest,
  getLicensesGenerationSucceess,
  getLicensesGenerationFailure,
  generateLicenseFileRequest,
  generateLicenseFileSucceess,
  generateLicenseFileFailure,
  getLicensesHistoryRequest,
  getLicensesHistorySucceess,
  getLicensesHistoryFailure,
  getLicenseByIdRequest,
  getLicenseByIdSucceess,
  getLicenseByIdFailure,
} from './licenseSlice';

import LicenseService from 'services/api/LicenseService';
import ItemsPerPage from 'core/shared/enum/ItemsPerPage';

const getLicensesGeneration = (page: number, itemsPerPage: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getLicensesGenerationRequest());
    const response = await LicenseService.getLicenses(false, page, itemsPerPage);
    dispatch(getLicensesGenerationSucceess({
      licenses: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getLicensesGenerationFailure(err.toString()));
  }
};

const generateLicenseFile = (id: string, data: File): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(generateLicenseFileRequest());
    const response = await LicenseService.generateLicenseFile(id, data);
    dispatch(generateLicenseFileSucceess({
      file: response.data,
      name: response.headers["content-disposition"].split("filename=")[1],
    }));
  } catch (err) {
    dispatch(generateLicenseFileFailure(err.toString()));
  }
};

const getLicensesHistory = (page: number, itemsPerPage: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getLicensesHistoryRequest());
    const response = await LicenseService.getLicenses(true, page, itemsPerPage);
    dispatch(getLicensesHistorySucceess({
      licenses: response.data.data,
      totalItems: response.data.totalItems
    }));
  } catch (err) {
    dispatch(getLicensesHistoryFailure(err.toString()));
  }
};

const getLicenseById = (id: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(getLicenseByIdRequest());
    const response = await LicenseService.getLicenseById(id);
    dispatch(getLicenseByIdSucceess(response.data));
  } catch (err) {
    dispatch(getLicenseByIdFailure(err.toString()));
  }
};

const downloadLicense = (id: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const response = await LicenseService.downloadLicense(id);
    const name = response.headers["content-disposition"].split("filename=")[1];
    const blob = new Blob([response.data], { type: 'application/zip' })
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log(err.toString());
  }
};

const resetPagination = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateLicensesGenerationPage(1));
    dispatch(updateLicensesHistoryPage(1));
    dispatch(updateLicensesGenerationItemsPerPage(ItemsPerPage.TEN));
    dispatch(updateLicensesHistoryItemsPerPage(ItemsPerPage.TEN));
  } catch (err) {
    console.log(err);
  }
};

export {
  getLicensesGeneration,
  generateLicenseFile,
  getLicensesHistory,
  downloadLicense,
  getLicenseById,
  resetPagination,
};
