import styled from '@emotion/styled/macro';

const BaseLayout: React.FC = ({ children }) => (
  <Layout>
    <Content>{children}</Content>
    <Footer>©2021 SamanTree Medical All rights reserved</Footer>
  </Layout>
);

export default BaseLayout;


const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #F0F2F5;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  width: 100%;
`;

const Footer = styled.footer`
  flex: 0 0 auto;
  padding: 60px;
  color: #00000073;
`;
