const routes = {
  index: {
    path: '/',
    exact: true,
  },
  signin: {
    path: '/signin',
    exact: true,
  },
  requestRecoverPassword: {
    path: '/recover-password',
    exact: true,
  },
  recoverPassword: {
    path: '/password-reset',
    exact: true,
  },
  licenseGenerationList: {
    path: '/license-generation/licenses',
    exact: true,
  },
  licenseGenerate: {
    path: '/license-generation/:id/generate',
    exact: true,
  },
  licenseHistory: {
    path: '/license-history',
    exact: true,
  },
  licensesProfile: {
    path: '/licenses/:id',
    exact: false,
  },
  account: {
    path: '/account',
    exact: true,
  },
  accountPasswordUpdate: {
    path: '/account/password-update',
    exact: true,
  },
  instructionsForUse: {
    path: '/ifu',
    exact: true,
  },
};

export default routes;
