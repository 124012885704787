import { RootState } from 'store/store';

export const selectIsAuth = (state: RootState): boolean => state.user.user.isAuth;
export const selectUserData = (state: RootState) => ({
  username: state.user.user.username,
  userId: state.user.user.userId,
});
export const selectAuthStatus = (state: RootState): string => state.user.user.signInStatus;
export const selectAuthError = (state: RootState): string => state.user.user.error;

export const selectAccount = (state: RootState) => state.user.account.account;

export const selectUpdateAccountPasswordStatus = (state: RootState): string => state.user.accountActions.updateAccountPasswordStatus;
export const selectAccountActionsError = (state: RootState): string => state.user.accountActions.error;
export const selectAccountActionsMessage = (state: RootState): string => state.user.accountActions.message;
export const selectAppData = (state: RootState) => state.user.appData.appData;


export const selectRequestResetPasswordStatus = (state: RootState): string => state.user.resetPassword.requestResetPasswordStatus;
export const selectValidateTokenStatus = (state: RootState): string => state.user.resetPassword.validateTokenStatus;
export const selectResetPasswordStatus = (state: RootState): string => state.user.resetPassword.resetPasswordStatus;
export const selectResetPasswordError = (state: RootState): string => state.user.resetPassword.error;