import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import routes from 'config/Route';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { selectRequestResetPasswordStatus } from 'store/user/userSelector';
import { requestResetPassword } from 'store/user/userThunk';
import { resetRequestResetPassword } from 'store/user/userSlice';
import BaseLayout from 'components/layouts/BaseLayout';

const RequestPasswordRecovery: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const requestResetPasswordStatus = useSelector(selectRequestResetPasswordStatus);
  const isRequestResetPasswordLoading = requestResetPasswordStatus === RequestStatus.LOADING;
  const isRequestResetPasswordSuccess = requestResetPasswordStatus === RequestStatus.SUCCEESS;

  const onFinish = async (values: { email: string }) => {
    console.log(values.email);
    dispatch(requestResetPassword(values.email));
  };

  useEffect(() => {
    if (isRequestResetPasswordSuccess) {
      notification.success({
        message: `Success`,
        description: 'Email sent successfully. Please check your email and follow instructions.',
        placement: 'bottomRight',
      });
      history.push(routes.signin.path);
    }
  }, [history, isRequestResetPasswordSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  
  useEffect(() => {
    return () => {
      dispatch(resetRequestResetPassword());
    }
  }, [dispatch]);

  return (
    
    <BaseLayout>
      <Container style={{ width: "50%" }}>
        <Container style={{ marginBottom: "30px"}}>
          <Header1>SamanTree Medical</Header1>
          <Header2>License Management System</Header2>
        </Container>
        <Header3>Getting back into your account</Header3>
        <Header3 style={{ marginBottom: "15px" }}>Enter your email to reset password</Header3>
        <Form style={{ width: "40%" }} onFinish={onFinish}>
          <Form.Item name="email" rules={[{ required: true }]} noStyle>
            <Email
              type="email"
              placeholder="Enter your email"
              size="large"
            />
          </Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isRequestResetPasswordLoading}
            block
          >
            Reset password
          </Button>
        </Form>
        <Link style={{ margin: "10px 10px", padding: "5px" }} to={routes.signin.path}>
          Back to login
        </Link>
      </Container>
    </BaseLayout>
  );
};

export default RequestPasswordRecovery;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  margin: 0 auto;
`;

const Header1 = styled.h1`
  font-size: 38px;
  margin-bottom: 12px;
  font-weight: 750;
  line-height: 1;
  color: #000000CC;
  text-align: center;
`;

const Header2 = styled.h2`
  letter-spacing: 0.005em;
  color: #00000073;
`;

const Header3 = styled.h3`
  letter-spacing: 0.005em;
  color: #00000073;
`;

const Email = styled(Input)`
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 24px;
`;