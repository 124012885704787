import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from "dateformat";
import styled from '@emotion/styled/macro';
import { PageHeader, Layout, Table, Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { License } from 'core/license/License';
import RequestStatus from 'core/shared/enum/RequestStatus';
import { selectGetLicenseHistoryStatus, selectGetLicensesHistory, selectLicenseHistoryItemsPerPage, selectLicenseHistoryPage, selectLicenseHistoryTotalItems } from 'store/license/licenseSelector';
import { getLicensesHistory, downloadLicense } from 'store/license/licenseThunk';
import { resetGetLicensesHistory, updateLicensesHistoryItemsPerPage, updateLicensesHistoryPage } from 'store/license/licenseSlice';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const LicenseHistory: React.FC = () => {
  const dispatch = useDispatch();

  const getLicenseHistoryStatus = useSelector(selectGetLicenseHistoryStatus);
  const licensesHistory = useSelector(selectGetLicensesHistory);
  const licenseHistoryPage = useSelector(selectLicenseHistoryPage);
  const licenseHistoryTotalItems = useSelector(selectLicenseHistoryTotalItems);
  const licenseHistoryItemsPerPage = useSelector(selectLicenseHistoryItemsPerPage);

  useEffect(() => {
    dispatch(getLicensesHistory(licenseHistoryPage, licenseHistoryItemsPerPage));
  }, [dispatch, licenseHistoryPage, licenseHistoryItemsPerPage]);

  useEffect(() => {
    return () => {
      dispatch(resetGetLicensesHistory());
    }
  }, [dispatch]);

  const onPageChange = (page: number) => dispatch(updateLicensesHistoryPage(page));

  const columns: ColumnsType<License> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => (
        <Link to={`licenses/${record.id}`}>{record.name}</Link>
      ),
      fixed: 'left',
    },
    {
      title: 'Configuration',
      dataIndex: 'configuration',
        render: (text, record) => (
          <div>
            <ProductType>{record.baseConfiguration.productName}{' '}</ProductType>
            (<span>
              {!record.baseConfiguration.count && !record.baseConfiguration.days && <span>Permanent</span>}
              {record.baseConfiguration.count && <span>Count of uses: {record.baseConfiguration.count};</span>}
              {record.baseConfiguration.count && record.baseConfiguration.days && ' '}
              {record.baseConfiguration.days && <span>Days: {record.baseConfiguration.days};</span>}
            </span>)
            <span>
              {
                record.configurations.map((config, index) => (
                  <div key={index}>
                    <ProductType>+ {config.productName}{' '}</ProductType>
                    (<span>
                      {!config.count && !config.days && <span>Permanent</span>}
                      {config.count && <span>Count of uses: {config.count};</span>}
                      {config.count && config.days && ' '}
                      {config.days && <span>Days: {config.days};</span>}
                    </span>)
                  </div>
                ))
              }
            </span>
          </div>
        ),
    },
    {
      title: 'Generated by',
      dataIndex: 'generatedBy',
      render: (text, record) => (
        <span>
          {record.generatedBy.firstName} {record.generatedBy.lastName}
        </span>
      ),
    },
    {
      title: 'Generation date',
      dataIndex: 'generationDate',
      render: (text, record) => (
        <span>
          {dateFormat(new Date(record.generationDate), "dd.mm.yyyy HH:MM:ss")}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Space size="middle" style={{ wordBreak: "normal"}}>
            <Button style={{ padding: 0 }} type="link" onClick={() => dispatch(downloadLicense(record.id))}>
              Download
            </Button>
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="License History"
        ghost={false}
      >
      </PageHeader>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: '#fff',
        }}
      >
        <Table
          scroll={{ x: "max-content" }}
          title={() => (<TitleContainer>
            <h2>Licenses</h2>
          </TitleContainer>)}
          loading={getLicenseHistoryStatus === RequestStatus.LOADING}
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: licenseHistoryItemsPerPage,
            current: licenseHistoryPage,
            total: licenseHistoryTotalItems,
            onChange: onPageChange,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {dispatch(updateLicensesHistoryPage(1)); dispatch(updateLicensesHistoryItemsPerPage(size))},
            showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={columns}
          dataSource={licensesHistory}
        />
      </Content>
    </>
  );
};

export default LicenseHistory;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductType = styled.span`
  font-weight: 500;
  font-style: italic;
`;
