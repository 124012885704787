import { createSlice, PayloadAction, combineReducers } from '@reduxjs/toolkit';

import RequestStatus from 'core/shared/enum/RequestStatus';
import { Account } from 'core/user/User';

import { UserInitialState, AccountInitialState, AccountActionsState, AppDataInitialState, ResetPasswordInitialState } from './userTypes';
import AppData from 'core/user/AppData';

const userInitialState: UserInitialState = {
  signInStatus: RequestStatus.IDLE,
  signOutStatus: RequestStatus.IDLE,
  isAuth: false,
  username: '',
  userId: '',
  error: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    updateUserData: (state, action: PayloadAction<{username: string; userId: string; isAuth: boolean }>) => {
      state.username = action.payload.username;
      state.userId = action.payload.userId;
      state.isAuth = action.payload.isAuth;
    },
    signInRequest: (state) => {
      state.error = '';
      state.signInStatus = RequestStatus.LOADING;
    },
    signInSucceess: (state, action: PayloadAction<{username: string; userId: string }>) => {
      state.signInStatus = RequestStatus.SUCCEESS;
      state.isAuth = true;
      state.username = action.payload.username;
      state.userId = action.payload.userId;
      state.error = '';
    },
    signInFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.signInStatus = RequestStatus.FAILURE;
      state.error = payload;
      state.isAuth = false;
    },
    signOutLocalAction: (state) => {
      state.isAuth = false;
      state.username = '';
      state.userId = '';
    },
    signOutRequest: (state) => {
      state.error = '';
      state.signOutStatus = RequestStatus.LOADING;
    },
    signOutSucceess: (state) => {
      state.signOutStatus = RequestStatus.SUCCEESS;
      state.isAuth = false;
      state.username = '';
      state.userId = '';
    },
    signOutFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.signOutStatus = RequestStatus.FAILURE;
    },
    signOutReset: (state) => {
      state.signOutStatus = RequestStatus.IDLE;
    },
  },
});


const resetPasswordInitialState: ResetPasswordInitialState = {
  requestResetPasswordStatus: RequestStatus.IDLE,
  validateTokenStatus: RequestStatus.IDLE,
  resetPasswordStatus: RequestStatus.IDLE,
  error: '',
};

export const resetPasswordSlice = createSlice({
  name: 'resetPasswordSlice',
  initialState: resetPasswordInitialState,
  reducers: {
    requestResetPasswordRequest: (state) => {
      state.error = "";
      state.requestResetPasswordStatus = RequestStatus.LOADING;
    },
    requestResetPasswordSucceess: (state, action: PayloadAction) => {
      state.requestResetPasswordStatus = RequestStatus.SUCCEESS;
    },
    requestResetPasswordFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.requestResetPasswordStatus = RequestStatus.FAILURE;
    },
    resetRequestResetPassword: (state, action: PayloadAction) => {
      state.error = "";
      state.requestResetPasswordStatus = RequestStatus.IDLE;
    },
    validateTokenRequest: (state) => {
      state.error = "";
      state.validateTokenStatus = RequestStatus.LOADING;
    },
    validateTokenSucceess: (state, action: PayloadAction) => {
      state.validateTokenStatus = RequestStatus.SUCCEESS;
    },
    validateTokenFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.validateTokenStatus = RequestStatus.FAILURE;
    },
    resetValidateToken: (state, action: PayloadAction) => {
      state.error = "";
      state.validateTokenStatus = RequestStatus.IDLE;
    },
    resetPasswordRequest: (state) => {
      state.error = "";
      state.resetPasswordStatus = RequestStatus.LOADING;
    },
    resetPasswordSucceess: (state, action: PayloadAction) => {
      state.resetPasswordStatus = RequestStatus.SUCCEESS;
    },
    resetPasswordFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.resetPasswordStatus = RequestStatus.FAILURE;
    },
    resetResetPassword: (state, action: PayloadAction) => {
      state.error = "";
      state.resetPasswordStatus = RequestStatus.IDLE;
    },
    resetResetPasswordSlice: () => ({ ...resetPasswordInitialState }),
  },
});

const accountInitialState: AccountInitialState = {
  getAccountStatus: RequestStatus.IDLE,
  account: null,
  error: '',
};

export const accountSlice = createSlice({
  name: 'accountSlice',
  initialState: accountInitialState,
  reducers: {
    getAccountRequest: (state) => {
      state.error = '';
      state.getAccountStatus = RequestStatus.LOADING;
    },
    getAccountSucceess: (state, action: PayloadAction<Account>) => {
      const { payload } = action;
      state.account = payload;
      state.getAccountStatus = RequestStatus.SUCCEESS;
    },
    getAccountFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getAccountStatus = RequestStatus.FAILURE;
    },
    resetGetAccount: () => ({ ...accountInitialState }),
  },
});

const appDataInitialState: AppDataInitialState = {
  getAppDataStatus: RequestStatus.IDLE,
  appData: null,
  error: '',
};

export const appDataSlice = createSlice({
  name: 'appDataSlice',
  initialState: appDataInitialState,
  reducers: {
    getAppDataRequest: (state) => {
      state.getAppDataStatus = RequestStatus.LOADING;
    },
    getAppDataSucceess: (state, action: PayloadAction<AppData>) => {
      const { payload } = action;
      state.appData = payload;
      state.getAppDataStatus = RequestStatus.SUCCEESS;
    },
    getAppDataFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.getAppDataStatus = RequestStatus.FAILURE;
    },
    resetGetAppData: () => ({ ...appDataInitialState }),
  },
});

const accountActionsInitialState: AccountActionsState = {
  updateAccountPasswordStatus: RequestStatus.IDLE,
  message: '',
  error: '',
};

export const accountActionsSlice = createSlice({
  name: 'accountActionsSlice',
  initialState: accountActionsInitialState,
  reducers: {
    updateAccountPassswordRequest: (state) => {
      state.error = '';
      state.message = '';
      state.updateAccountPasswordStatus = RequestStatus.LOADING;
    },
    updateAccountPassswordSucceess: (state, action: PayloadAction<{message: string}>) => {
      state.updateAccountPasswordStatus = RequestStatus.SUCCEESS;
      state.message = action.payload.message;
    },
    updateAccountPassswordFailure: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.error = payload;
      state.updateAccountPasswordStatus = RequestStatus.FAILURE;
    },
    resetUpdateAccountPasssword: () => ({ ...accountActionsInitialState }),
  },
});

export const {
  updateUserData,
  signInRequest,
  signInSucceess,
  signInFailure,
  signOutLocalAction,
  signOutRequest,
  signOutSucceess,
  signOutFailure,
  signOutReset,
} = userSlice.actions;

export const {
  requestResetPasswordRequest,
  requestResetPasswordSucceess,
  requestResetPasswordFailure,
  resetRequestResetPassword,
  validateTokenRequest,
  validateTokenSucceess,
  validateTokenFailure,
  resetValidateToken,
  resetPasswordRequest,
  resetPasswordSucceess,
  resetPasswordFailure,
  resetResetPassword,
  resetResetPasswordSlice,
} = resetPasswordSlice.actions;

export const {
  getAccountRequest,
  getAccountSucceess,
  getAccountFailure,
  resetGetAccount,
} = accountSlice.actions;

export const {
  getAppDataRequest,
  getAppDataSucceess,
  getAppDataFailure,
  resetGetAppData,
} = appDataSlice.actions;

export const {
  updateAccountPassswordRequest,
  updateAccountPassswordSucceess,
  updateAccountPassswordFailure,
  resetUpdateAccountPasssword,
} = accountActionsSlice.actions;

export default combineReducers({
  user: userSlice.reducer,
  account: accountSlice.reducer,
  accountActions: accountActionsSlice.reducer,
  appData: appDataSlice.reducer,
  resetPassword: resetPasswordSlice.reducer,
});
