import { License } from 'core/license/License';
import { LicenseStatistic } from 'core/license/License';
import { GetLicensesResponse } from 'core/license/LicenseResponse';
import ApiMethods from 'core/shared/enum/ApiMethod';

import http from './request';

const getLicenses = (generated: boolean, page: number, itemsPerPage: number): Promise<GetLicensesResponse> => {
  const offset = itemsPerPage * (page - 1);
  return http.request({
    url: `/clients/licenses?generated=${generated}&max=${itemsPerPage}&offset=${offset}`,
    method: ApiMethods.GET,
  });
};

const generateLicenseFile = (id: string, data: File) => {
  const formData = new FormData();
  formData.append('file', data);
  return http.request<File>({
    url: `/clients/licenses/${id}/generate`,
    method: ApiMethods.POST,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

const getLicenseById = (id: string): Promise<{data: License}> => {
  return http.request({
    url: `/clients/licenses/${id}`,
    method: ApiMethods.GET,
  });
};

const getLicenseStatistic = (id: string): Promise<{data: LicenseStatistic[]}> => {
  return http.request({
    url: `/clients/licenses/${id}/download-statistic`,
    method: ApiMethods.GET,
  });
};

const downloadLicense = (id: string) => {
  return http.request<File>({
    url: `/clients/licenses/${id}/download`,
    method: ApiMethods.POST,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const LicenseService = {
  getLicenses,
  generateLicenseFile,
  getLicenseById,
  getLicenseStatistic,
  downloadLicense,
};

export default LicenseService;
