import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import RouteType from 'core/shared/enum/RouteType';
import { useAppConfig } from 'components/context/AppConfigContext';

interface IAuthRoute {
  type: RouteType;
  isAuth: boolean;
  path: string | string[];
  exact: boolean;
  component: React.ComponentType;
}

interface LocationState {
  from?: string
};

const AuthRoute: React.FC<IAuthRoute> = ({ type, isAuth, component, path, exact }) => {
  const { routes } = useAppConfig();
  const { state, pathname } = useLocation();
  
  const from = (state as LocationState)?.from || "";

  if (type === RouteType.GUEST && isAuth) return <Redirect to={{pathname: routes.index.path, state: { from: from }}} />;
  if (type === RouteType.PRIVATE && !isAuth) return <Redirect to={{pathname: routes.signin.path, state: { from: pathname }}} />;

  return <Route path={path} exact={exact} component={component} />;
};
export default AuthRoute;
